
// @ts-nocheck
import locale__home_runner_work_divine_divine_apps_dvn_nuxt_platform_i18n_locales_en_yaml from "../i18n/locales/en.yaml";
import locale__home_runner_work_divine_divine_apps_dvn_nuxt_platform_i18n_locales_fr_yaml from "../i18n/locales/fr.yaml";


export const localeCodes =  [
  "en",
  "fr"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/locales/en.yaml", load: () => Promise.resolve(locale__home_runner_work_divine_divine_apps_dvn_nuxt_platform_i18n_locales_en_yaml), cache: true }],
  "fr": [{ key: "../i18n/locales/fr.yaml", load: () => Promise.resolve(locale__home_runner_work_divine_divine_apps_dvn_nuxt_platform_i18n_locales_fr_yaml), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute",
    "alternateLinkCanonicalQueries": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "language": "en-US",
      "name": "English",
      "dir": "ltr",
      "files": [
        "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/i18n/locales/en.yaml"
      ]
    },
    {
      "code": "fr",
      "language": "fr-FR",
      "name": "Français",
      "dir": "ltr",
      "files": [
        "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/i18n/locales/fr.yaml"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": true,
    "cookieDomain": null,
    "cookieKey": "divine-i18n",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://www.divinesamples.com",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en-US",
    "name": "English",
    "dir": "ltr",
    "files": [
      {
        "path": "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/i18n/locales/en.yaml"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr-FR",
    "name": "Français",
    "dir": "ltr",
    "files": [
      {
        "path": "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/i18n/locales/fr.yaml"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"

import payload_plugin_EmsrEFm0ie from "/home/runner/work/divine/divine/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_EQFRRQQ5Tf from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_@types+node@22.13.1_eslint@9.20.0_rollup@4.34.6_sass@_gjok4a3guhvoa4veydqmmsmqje/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lpTnuXSmNn from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_@types+node@22.13.1_eslint@9.20.0_rollup@4.34.6_sass@_gjok4a3guhvoa4veydqmmsmqje/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XAEuZ7ZVmh from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_@types+node@22.13.1_eslint@9.20.0_rollup@4.34.6_sass@_gjok4a3guhvoa4veydqmmsmqje/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t6d9wRnU5S from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt-site-config@3.0.6_vite@6.1.0_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_wLNJHUtbYv from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_@types+node@22.13.1_eslint@9.20.0_rollup@4.34.6_sass@_gjok4a3guhvoa4veydqmmsmqje/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_WkwN3BbGDl from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_@types+node@22.13.1_eslint@9.20.0_rollup@4.34.6_sass@_gjok4a3guhvoa4veydqmmsmqje/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qHVPwYVAgT from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_@types+node@22.13.1_eslint@9.20.0_rollup@4.34.6_sass@_gjok4a3guhvoa4veydqmmsmqje/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8tl5YvJ4pQ from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_@types+node@22.13.1_eslint@9.20.0_rollup@4.34.6_sass@_gjok4a3guhvoa4veydqmmsmqje/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_eVs6OCoipX from "/home/runner/work/divine/divine/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/.nuxt/components.plugin.mjs";
import prefetch_client_4TfNhl4Hnj from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_@types+node@22.13.1_eslint@9.20.0_rollup@4.34.6_sass@_gjok4a3guhvoa4veydqmmsmqje/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_qZssA1ix2r from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt-gtag@3.0.2/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_6IuuaSDPMn from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_eslint@9.20.0_rollup@4.34.6_typescript@5.7.3_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_DhDNz9NZdE from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_eslint@9.20.0_rollup@4.34.6_typescript@5.7.3_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_NjJGDdCh8r from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_eslint@9.20.0_rollup@4.34.6_typescript@5.7.3_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import index_0BaboSgNMq from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/layouts/index.ts";
import index_kyvreQaQna from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/vuetify/index.ts";
import casl_QTBkbB6FCs from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/casl.ts";
import electron_ipc_api_MSICFOjjP3 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/electron-ipc-api.ts";
import nuxt_error_hooks_b1Mep9do5h from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/nuxt-error-hooks.ts";
import nuxt_page_hooks_ISYXsuIs9F from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/nuxt-page-hooks.ts";
import perfect_scrollbar_eDszbQJYAy from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/perfect-scrollbar.ts";
import trpc_client_sNkZYQPPl5 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/trpc-client.ts";
import ssg_detect_syWZowPR3C from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_eslint@9.20.0_rollup@4.34.6_typescript@5.7.3_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_EmsrEFm0ie,
  revive_payload_client_EQFRRQQ5Tf,
  unhead_lpTnuXSmNn,
  router_XAEuZ7ZVmh,
  _0_siteConfig_t6d9wRnU5S,
  payload_client_wLNJHUtbYv,
  navigation_repaint_client_WkwN3BbGDl,
  check_outdated_build_client_qHVPwYVAgT,
  chunk_reload_client_8tl5YvJ4pQ,
  plugin_vue3_eVs6OCoipX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4TfNhl4Hnj,
  plugin_client_qZssA1ix2r,
  switch_locale_path_ssr_6IuuaSDPMn,
  route_locale_detect_DhDNz9NZdE,
  i18n_NjJGDdCh8r,
  index_0BaboSgNMq,
  index_kyvreQaQna,
  casl_QTBkbB6FCs,
  electron_ipc_api_MSICFOjjP3,
  nuxt_error_hooks_b1Mep9do5h,
  nuxt_page_hooks_ISYXsuIs9F,
  perfect_scrollbar_eDszbQJYAy,
  trpc_client_sNkZYQPPl5,
  ssg_detect_syWZowPR3C
]
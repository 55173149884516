import routerOptions0 from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_@types+node@22.13.1_eslint@9.20.0_rollup@4.34.6_sass@_gjok4a3guhvoa4veydqmmsmqje/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}